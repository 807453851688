/*eslint-disable*/
import React from "react";
import { graphql } from 'gatsby';

// seo components
import { GatsbySeo, BreadcrumbJsonLd } from 'gatsby-plugin-next-seo';
import { GetSiteMetadata } from 'hooks/GetSiteMetadata';
import { getSrc } from "gatsby-plugin-image"

import HeaderTemplate from "components/Header/HeaderTemplate.js";
import HeaderScentsy from "components/Header/HeaderScentsy.js";
import SearchComponent from "components/Search/SearchComponent.js";
import FooterTemplate from "components/Footer/FooterTemplate.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
import Button from "components/CustomButtons/Button.js";

// @material-ui/icons
import FormatAlignLeft from "@material-ui/icons/FormatAlignLeft";

import SectionTeam from "views/HomePage/Sections/SectionTeam.js";

import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import Hidden from "@material-ui/core/Hidden";

// nodejs library that concatenates classes
import classNames from "classnames";

import useIsClient from 'hooks/useIsClient';

import styles from "assets/jss/material-kit-pro-react/views/blogPostPageStyle.js";

const useStyles = makeStyles(styles);

export const query = graphql`
  query aboutPageBannerImage {
    bannerImage: imageSharp(resize: {originalName: {eq: "kathryn-scentsy-bars.jpeg"}}) {
      gatsbyImageData(quality: 50)
    }
    bannerImageMobile: imageSharp(resize: {originalName: {eq: "kathryn-scentsy-bars.jpeg"}}) {
      gatsbyImageData(width: 1200)
    }
  }
`

export default function AboutPage(props) {

  const { isClient } = useIsClient();
  if (isClient) {
    var isMobile = window.matchMedia('(max-width: 600px)').matches;
    var bannerImage;
    if (isMobile) {
      bannerImage = getSrc(props.data.bannerImageMobile)
    } else {
      bannerImage = getSrc(props.data.bannerImage)
    }
  }

  // for SEO path
  const { siteMetadata } = GetSiteMetadata();
  var path = props.location.pathname;
  if (path.includes("/usa")) {
    path = path.replace("/usa", "");
  }

  const classes = useStyles();

  return (
    <div>

      <HeaderTemplate
        locale={props.pageContext.locale}
        location={props.location}
      />

      <GatsbySeo
        title={'Simply, Authentically, Generously Kathryn Gibson ' + props.pageContext.locale.appendToTitle}
        description={'Since 2011, I have grown my Scentsy business from a casual hobby to a vibrant team of people who are positive and encouraging. Learn more.'}
        canonical={siteMetadata.siteUrl + props.pageContext.locale.path + path}
        language={props.pageContext.locale.isoLanguageCode}
        languageAlternates={[
          {
            hrefLang: props.pageContext.locale.isoLanguageCode,
            href: siteMetadata.siteUrl + props.pageContext.locale.path + path,
          },
          {
            hrefLang: props.pageContext.altLocale.isoLanguageCode,
            href: siteMetadata.siteUrl + props.pageContext.altLocale.path + path,
        }
        ]}
        openGraph={{
          type: 'website',
          title: 'Simply, Authentically, Generously Kathryn Gibson ' + props.pageContext.locale.appendToTitle,
          description: 'Since 2011, I have grown my Scentsy business from a casual hobby to a vibrant team of people who are positive and encouraging. Learn more.',
          url: siteMetadata.siteUrl + props.pageContext.locale.path + path,
          site_name: 'SCENTSY Online Store | Home Fragrance Biz',
          locale: props.pageContext.locale.ogLanguageCode,
          images: [
            {
              url: 'https://homefragrancebiz.com/files/images/seo/kathryn-scentsy-bars.jpeg',
              width: 1440,
              height: 1800,
              alt: 'Picture of Kathryn with three Scentsy bars.',
            },
          ],
        }}
      />
      <BreadcrumbJsonLd
        itemListElements={[
          {
            position: 1,
            name: 'Home',
            item: 'https://homefragrancebiz.com',
          },
          {
            position: 2,
            name: 'About Me ',
            item: siteMetadata.siteUrl + props.pageContext.locale.path + path,
          },
        ]}
      />

      <Parallax image={bannerImage} filter="dark">
        <div className={classes.container}>
          <HeaderScentsy />
          <GridContainer justifyContent="center">
            <GridItem md={8} className={classes.textCenter}>
              <Hidden only={['sm','md','lg','xl']} implementation="js">
                <Typography variant="h5" component="h1" className={classes.title}>
                  About me
                </Typography>
              </Hidden>
              <Hidden only={['xs']} implementation="js">
                <h1 className={classes.title}>
                  About me
                </h1>
              </Hidden>
              <Typography variant="body1" component="p" className={classes.subtitle}>
                <p>Since 2011, I have grown my Scentsy business from a casual hobby to a vibrant team of people who are positive and encouraging. We believe in throwing out the ideas about things you “have to” do in network marketing and instead are part of something simple but special.</p>
                <p>I love to travel and am always planning my next trip! Otherwise, you can find me at home with my husband, our two sons and our energetic dog (or hiding from them all with a cup of coffee!)</p>
              </Typography>
              <br />
              <Button color="primary" size="lg" round href="#post">
                <FormatAlignLeft />Read more
              </Button>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classes.main} id="post">
        <div className={classes.container}>
          <SectionTeam
            sectionExpanded="true"
            sectionBaseHLevel="h2"
          />
        </div>
      </div>
      <FooterTemplate
        locale={props.pageContext.locale}
      />
    </div>
  );
}
